.container-modal .modal {
  border-radius: 0;
  background-color: #504D4D;
  color: white;
  padding: 2em;
}

.container-modal .modal-content {
  padding: 0 2em;
}

.container-modal .modal-content p {
  padding: 0;
}

.container-modal .modal-content h4 {
  color: #deb767;
}

.container-modal .modal-content strong {
  font-size: 1.5rem;
  color: #deb767;
}

.container-modal .modal-footer {
  background-color: #504D4D;
  padding-right: 3em;
}

.container-modal .modal-footer a {
  font-weight: bold !important;
}

.container-modal .modal-close {
  background-color: #deb767;
}
