@font-face {
  font-family: WorkSans-Thin;
  src: url("../ttf/WorkSans-Thin.ttf");
  font-display: swap;
}
@font-face {
  font-family: WorkSans-Regular;
  src: url("../ttf/WorkSans-Regular.ttf");
  font-display: swap;
}
@font-face {
  font-family: WorkSans-Bold;
  src: url("../ttf/WorkSans-Bold.ttf");
  font-display: swap;
}
* {
  font-family: WorkSans-Regular, sans-serif;
}

hr.gold {
  border-color: rgba(222, 182, 103, 0.6);
  margin-top: 2em;
  margin-bottom: 2em;
}

body, html {
  margin: 0;
  padding: 0;
  width: 100vw;
  overflow-x: hidden;
  background-color: #504D4D !important;
}

.gold {
  color: #deb767 !important;
}

.dark-gold {
  color: rgba(222, 182, 103, 0.6) !important;
}

.bg-gold {
  background-color: #deb767 !important;
}

.bg-dark-gold {
  background-color: rgba(222, 182, 103, 0.6) !important;
}

.border-gold {
  border: 2px solid #deb767 !important;
}

.border-dark-gold {
  border: 2px solid rgba(222, 182, 103, 0.6) !important;
}

.btn-dark-gold {
  width: 120px;
  height: 35px;
  border: none;
  color: white;
  background-color: rgba(222, 182, 103, 0.6) !important;
  cursor: pointer;
}
.btn-dark-gold:hover {
  background-color: rgba(222, 182, 103, 0.3) !important;
}
.btn-dark-gold:active, .btn-dark-gold:focus {
  background-color: rgba(222, 182, 103, 0.3) !important;
}

a {
  text-decoration: none !important;
}

.btn-shadow {
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
}

a {
  border-radius: 0 !important;
}

button {
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
  border-radius: 0 !important;
}
button:hover {
  box-shadow: 0 20px 20px -5px rgba(0, 0, 0, 0) !important;
}

.tabs li.indicator {
  height: 3px !important;
  background-color: #deb767;
}
.tabs .tab {
  background-color: #504D4D;
}
.tabs .tab a {
  color: #deb767 !important;
}
.tabs .tab a:focus, .tabs .tab a:active {
  background-color: transparent !important;
}

.progress {
  background-color: rgba(222, 182, 103, 0.6) !important;
}
.progress .indeterminate:before, .progress .indeterminate:after {
  background-color: #deb767 !important;
}

input::placeholder {
  color: rgba(222, 182, 103, 0.3);
}

@media screen and (max-width: 992px) {
  .tabs .tab a {
    font-size: 10px !important;
  }
}
