.course-levels {
  padding-top: 6em;
  position: relative;
}

.course-indicator {
  font-size: 80px;
  cursor: pointer;
}

.course-card {
  justify-content: space-between;
  background-color: rgba(0, 0, 0, 0);
  box-shadow: none;
}
.course-card .course-image {
  cursor: pointer;
  margin-bottom: 30px;
}
.course-card .course-image img {
  border-radius: 50%;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.4);
  display: block;
  position: relative;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
}
.course-card .course-summary {
  text-align: center;
  color: white;
  background-size: cover;
  background-repeat: no-repeat;
  align-items: center;
  padding-top: 0;
}
.course-card .course-summary .card-action {
  border-top: none;
}
.course-card .course-summary h2 {
  margin-top: 0;
  text-align: left;
}
.course-card .course-summary p {
  text-align: left;
  font-size: 16px;
}

h3 {
  margin-top: 0;
  color: white;
}

.course-description {
  background: url("../../assets/img/course-description-bg.jpg") no-repeat;
  background-size: cover;
  padding-bottom: 10vh;
  padding-top: 10vh;
}
.course-description .educator-image {
  height: 15em;
  width: 15em;
  border-radius: 50%;
  background-size: cover;
}
.course-description .bottom-line-gold {
  padding-bottom: 2em;
  border-bottom: 2px solid #deb767;
}
.course-description .bold-text {
  font-weight: bold;
  font-size: 1.2rem;
}
.course-description ul {
  padding-left: 2em;
}
.course-description ul li {
  color: white;
}
.course-description .download-pdf {
  padding-top: 1.5em;
  display: flex;
  flex-direction: row-reverse;
}
.course-description .download-pdf div {
  border: 2px solid #deb767;
  width: 150px;
  height: 150px;
  text-align: center;
  padding: 0 10px 0 10px;
}
.course-description .download-pdf div h4 {
  margin-bottom: 20px;
}
.course-description .download-pdf div .download-btn {
  cursor: pointer;
  width: 100px;
}
.course-description h1,
.course-description h2,
.course-description h3,
.course-description h4,
.course-description h5,
.course-description h6 {
  color: #deb767;
}
.course-description p {
  color: white;
}

@media screen and (max-width: 1300px) {
  .course-description .bottom-line-gold p {
    padding-left: 3em;
  }
}
@media screen and (max-width: 992px) {
  .course-card .course-card-title-wrapper {
    height: 3em;
    margin-bottom: 4em;
  }

  .card-image {
    padding: 0 3em 0 3em !important;
  }

  .course-description .bottom-line-gold p {
    padding: 0;
  }
}
@media screen and (max-width: 900px) {
  .card-image {
    padding: 0 1em 0 1em !important;
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 700px) {
  .card-image {
    padding: 0 1em 0 1em !important;
    margin-bottom: 30px;
  }
  .card-image img {
    margin: auto;
    width: 20em !important;
  }
}
@media screen and (max-width: 600px) {
  .course-card {
    margin-bottom: 0;
    padding-bottom: 0;
  }
}
