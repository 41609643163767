#contact {
  min-height: 80vh;
  padding-top: 17vh;
}

#contact .circle-button-container {
  margin-bottom: 10vh;
}

#contact .contact-content {
  background-color: #444343;
}

#contact .contact-list {
  padding: 1em 1em 3em;
  height: 100%;
}

#contact .contact-map img {
  width: 100%;
  margin-bottom: -5px;
}
