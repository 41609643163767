.home-body .about {
  margin-bottom: 5em;
}
.home-body .about .circle-image {
  color: white;
  font-family: WorkSans-Thin, sans-serif;
}
.home-body .about .circle-image img {
  margin-top: 2vh;
  width: 100%;
}
.home-body .about .about-text {
  color: white;
  font-size: 16px;
  font-family: WorkSans-Regular, sans-serif;
}
.home-body .about .about-text h1, .home-body .about .about-text h2, .home-body .about .about-text h3, .home-body .about .about-text h4, .home-body .about .about-text h5, .home-body .about .about-text h6 {
  font-family: WorkSans-Thin, sans-serif;
  margin-top: 0.1em;
  color: #deb767;
}
.home-body .about .about-text .btn-contain button {
  font-family: WorkSans-Regular, sans-serif;
  padding: 0;
  text-transform: uppercase;
  width: 160px;
  height: 40px;
  display: inline-block;
  border: none;
  color: white;
  font-size: 16px;
  background-color: rgba(222, 182, 103, 0.6) !important;
  cursor: pointer;
  margin-right: 1em;
}
.home-body .about .about-text .btn-contain button:hover {
  background-color: rgba(222, 182, 103, 0.3) !important;
}
.home-body .about .about-text .btn-contain button:active, .home-body .about .about-text .btn-contain button:focus {
  background-color: rgba(222, 182, 103, 0.3) !important;
}
.home-body .about .about-text .btn-contain button a {
  width: 100%;
  color: white;
}
.home-body .particle-circle {
  margin-bottom: 10vh;
  background: url("../../assets/img/particle-circle.png") no-repeat top;
  background-size: 90%;
  position: relative;
  width: 426px;
  height: 426px;
}
.home-body .particle-circle img {
  width: 30%;
}
.home-body .particle-circle .contact-icon {
  position: absolute;
  top: 18px;
  left: 16%;
}
.home-body .particle-circle .mail {
  width: 30%;
}
.home-body .testimony {
  max-width: 750px;
  background: url("../../assets/img/testimony-rectangle-background.png") no-repeat;
  background-size: cover;
  font-family: WorkSans-Regular, sans-serif;
  margin: 10vh auto;
  padding: 3em 5em 4em 5em;
  position: relative;
}
.home-body .testimony h4 {
  color: #deb767;
  margin-top: 0;
}
.home-body .testimony p {
  margin-top: 1em;
  color: white;
  font-size: 16px;
}
.home-body .testimony i {
  cursor: pointer;
  color: white;
  font-size: 60px;
  position: absolute;
  right: 2%;
  top: 36%;
}

@media screen and (max-width: 992px) {
  .home-body .about .about-text .btn-contain button {
    margin: 1em auto;
    display: block !important;
  }
}
