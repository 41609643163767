.circle-button {
  position: relative;
}

.circle-button .circle-button-texture {
  position: absolute;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  -webkit-box-shadow: 0 20px 20px -5px rgba(0, 0, 0, 0.4);
          box-shadow: 0 20px 20px -5px rgba(0, 0, 0, 0.4);
  -webkit-transition: all 500ms;
  transition: all 500ms;
}

.circle-button .circle-button-badge {
  position: absolute;
  top: 8%;
  left: 15%;
  width: 25%;
}

.circle-button .circle-button-caption {
  font-family: WorkSans-Regular, sans-serif;
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 1em;
  overflow-wrap: break-spaces;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  z-index: 10;
  text-align: center;
  color: white;
  height: 100%;
  width: 100%;
}

.circle-button .circle-button-caption a {
  text-decoration: none;
  color: #deb767;
}

.circle-button .circle-button-caption span {
  cursor: pointer;
  width: 100%;
  font-weight: bold;
}

.circle-button .circle-button-caption span:hover {
  color: rgba(222, 182, 103, 0.6);
}
