header {
  min-height: 100vh;
  height: 100vh;
}
header .carousel {
  width: 100vw;
  height: 100% !important;
  position: relative;
  user-select: none;
}
header .carousel .carousel-next, header .carousel .carousel-prev {
  z-index: 100;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent;
  border: none;
  color: white;
  box-shadow: none;
  cursor: pointer;
}
header .carousel .carousel-next:hover, header .carousel .carousel-prev:hover {
  color: #deb767;
}
header .carousel .carousel-next i, header .carousel .carousel-prev i {
  font-size: 8em;
}
header .carousel .carousel-prev {
  left: 0;
}
header .carousel .carousel-next {
  right: 0;
}
header .carousel .carousel-item2 {
  position: relative;
  height: 100vh;
}
header .carousel .carousel-item2 .container {
  margin: 0 auto;
  position: static;
  height: 100vh;
}
header .carousel .carousel-item2 .container .text-img {
  top: -15%;
  position: relative;
  height: 100vh;
  width: auto;
  z-index: 100;
}
header .carousel .carousel-item2 .slide-img, header .carousel .carousel-item2 .mobile-img {
  position: absolute;
  z-index: 10;
  left: 0;
  height: 100vh;
  min-height: 100vh;
  width: auto !important;
}
header .carousel .carousel-item2 .mobile-img {
  display: none;
}
header .carousel .carousel-item2 .slide-links {
  position: relative;
  top: -25%;
  left: 0;
  text-align: left;
}
header .carousel .carousel-item2 .slide-links a {
  display: inline-block;
  margin-right: 0.5em;
  z-index: 100;
  background-color: rgba(222, 182, 103, 0.6) !important;
  border: none;
  height: 2.5em;
  line-height: 2.5em;
  color: white;
  cursor: pointer;
  text-align: center;
  position: relative;
  width: 15em;
}
header .slider-wrapper, header .slider {
  height: 100%;
}
header .header-text {
  color: white;
}
header .header-text h1 {
  margin: 1.5rem;
}
header .header-text h5 {
  margin: 5px;
}
header .header-text h1,
header .header-text h2,
header .header-text h3,
header .header-text h4,
header .header-text h5,
header .header-text h6 {
  margin-left: 0;
}
header .header-text button {
  color: white;
  cursor: pointer;
  background: rgba(172, 132, 63, 0.6);
  border: none;
  width: 10rem;
  height: 3rem;
}
header .header-text .header-text-decor {
  border-bottom: white 2px solid;
  border-top: white 2px solid;
  width: 50%;
}

@media screen and (max-width: 992px) {
  header .header-text-decor {
    border-bottom: white 2px solid;
    border-top: white 2px solid;
    width: 100%;
  }
  header .carousel .carousel-item2 .container .overlay {
    top: -1.1em;
  }
  header .carousel .carousel-item2 a {
    display: block;
    font-size: 1em;
  }
}
@media (orientation: landscape) and (max-height: 600px) {
  header .slide-links a {
    height: 3rem !important;
  }
}
@media screen and (orientation: landscape) and (max-width: 992px) {
  header {
    height: 320vh;
  }
}
@media screen and (max-aspect-ratio: 1/1) {
  .carousel {
    width: 100vw;
    height: 100vh !important;
    position: relative;
  }
  .carousel .carousel-next, .carousel .carousel-prev {
    bottom: -5rem !important;
    transform: none !important;
    font-size: 0.5rem !important;
  }
  .carousel .carousel-item2 .mobile-img {
    display: block !important;
  }
  .carousel .carousel-item2 .text-img {
    top: -5% !important;
    width: 100vh !important;
    height: auto !important;
  }
  .carousel .carousel-item2 .slide-links {
    top: -7rem !important;
  }
  .carousel .carousel-item2 .slide-links a {
    margin-bottom: 0.5em;
  }
}
