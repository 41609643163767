.category-card:nth-of-type(7) {
  clear: both !important;
  background-color: red !important;
  display: block;
  margin: 0 auto;
}

.course-element-avatar {
  border-radius: 50%;
  padding: 0 !important;
  height: 13em;
  width: 13em;
  background: url("../../assets/img/course-avatar.png") no-repeat center;
  background-size: contain;
  transition: all 500ms;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.4);
}
.course-element-avatar:hover {
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0);
}
.course-element-avatar h1, .course-element-avatar h2, .course-element-avatar h3, .course-element-avatar h4, .course-element-avatar h5, .course-element-avatar h6 {
  font-family: WorkSans-Regular, sans-serif;
  padding: 1em;
  font-weight: bold;
  text-align: center;
  color: white;
}
.course-element-avatar h1:hover, .course-element-avatar h2:hover, .course-element-avatar h3:hover, .course-element-avatar h4:hover, .course-element-avatar h5:hover, .course-element-avatar h6:hover {
  color: #deb767;
}
.course-element-avatar .transparent-filter {
  background-color: rgba(80, 77, 77, 0.521);
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  overflow: hidden;
}
.course-element-avatar .transparent-filter img {
  width: 100%;
  height: 100%;
}
.course-element-avatar .transparent-filter .overlay {
  background-color: rgba(80, 77, 77, 0.521);
  width: 100%;
  height: 100%;
  position: absolute;
}

.offer-container {
  padding-top: 8vh;
  min-height: 60vh;
}
.offer-container .col {
  margin-top: 6em;
  display: flex;
  justify-content: center;
}

.monthly-special {
  width: 100vw;
}
