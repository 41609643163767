.treatment-list-container {
  padding-top: 8em;
  min-height: 70vh;
}
.treatment-list-container .treatment-elem {
  margin-bottom: 3em;
  display: flex;
}
.treatment-list-container .course-element-avatar {
  background-size: 110%;
  margin: 0 auto;
}
.treatment-list-container .course-element-avatar h1,
.treatment-list-container .course-element-avatar h2,
.treatment-list-container .course-element-avatar h3,
.treatment-list-container .course-element-avatar h4,
.treatment-list-container .course-element-avatar h5,
.treatment-list-container .course-element-avatar h6 {
  cursor: default;
  color: white;
}
.treatment-list-container .treatment-elem > .col:first-of-type {
  display: flex;
  justify-content: center;
}
.treatment-list-container .gold-line {
  border-right: #deb767 solid 1px;
  height: 80%;
  width: 3px;
}
.treatment-list-container .avatar-title {
  text-align: center;
}
.treatment-list-container img {
  height: 180px;
}
.treatment-list-container .image {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.treatment-list-container .description {
  color: white;
  padding-left: 1em;
  padding-right: 2.5em;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.treatment-list-container .description p {
  display: block;
  font-family: WorkSans-Regular, sans-serif;
  margin-top: 0;
  font-size: 16px;
  padding-right: 2em;
}
.treatment-list-container .time-price {
  background: url("../../assets/img/block-background.png") no-repeat;
  background-size: cover;
  font-family: WorkSans-Regular, sans-serif;
  color: white;
  padding: 1em;
  position: relative;
  text-align: left;
}
.treatment-list-container .time-price .treatment-purchase {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 1em;
}
.treatment-list-container .time-price .treatment-purchase p {
  display: block;
}
.treatment-list-container .time-price .col {
  padding-left: 2em;
  padding-right: 2em;
}
.treatment-list-container .time-price .btn-treatment-purchase {
  display: inline-block;
  background-color: rgba(222, 182, 103, 0.6);
  color: #444343;
  width: 11em;
  margin-bottom: 1em;
  height: 2em;
  vertical-align: center;
  line-height: 2em;
  cursor: pointer;
  position: relative;
  text-align: left;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
}
.treatment-list-container .time-price .btn-treatment-purchase span {
  display: inline-block;
  width: 80%;
  text-align: center;
}
.treatment-list-container .time-price .btn-treatment-purchase i {
  position: absolute;
  right: 1em;
  top: 3px;
  font-size: 1.5em;
}
.treatment-list-container .time-price .gold-line {
  background-color: rgba(222, 182, 103, 0.6);
  position: absolute;
  left: -1.5em;
  top: -5%;
  height: 110%;
  width: 2px;
}
.treatment-list-container .time-price h1,
.treatment-list-container .time-price h2,
.treatment-list-container .time-price h3,
.treatment-list-container .time-price h4,
.treatment-list-container .time-price h5,
.treatment-list-container .time-price h6 {
  text-align: left;
  color: #deb767;
}
.treatment-list-container .monthly-special {
  background: url("../../assets/img/monthly-special.png");
  width: 100vw;
}

@media screen and (max-width: 992px) {
  .treatment-list-container .course-element-avatar {
    margin-bottom: 1em;
  }
  .treatment-list-container .treatment-elem {
    display: block !important;
  }
  .treatment-list-container .treatment-elem:after {
    content: " ";
    background-color: rgba(222, 182, 103, 0.6) !important;
    color: #deb767 !important;
    display: block;
    position: relative;
    top: 1em;
    height: 2px;
  }
  .treatment-list-container .treatment-elem .avatar-title {
    text-align: center;
  }
  .treatment-list-container .treatment-elem .gold-line {
    display: none;
  }
  .treatment-list-container .treatment-elem .description p {
    padding: 0;
  }
  .treatment-list-container .time-price .col {
    text-align: center !important;
  }
}
