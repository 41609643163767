.faq-container {
  padding-top: 6em;
  padding-bottom: 6em;
}

.faq-container .faq-element {
  border-bottom: #deb767 solid 1px;
  padding-bottom: 1em;
}

.faq-container .faq-element p {
  color: white;
}
