#consultation-form-page {
  padding-top: 10em;
  min-height: 80vh;
}

#consultation-form-page .collapsible {
  -webkit-box-shadow: none;
          box-shadow: none;
  border-color: transparent;
}

#consultation-form-page .collapsible .collapsible-header {
  font-size: 18px;
  font-weight: bold;
  color: #deb767;
  border-color: transparent;
  background-color: #504d4d !important;
}

#consultation-form-page .collapsible .collapsible-body {
  border: none;
  color: white;
}
