.multiline-label-1 {
  margin-top: -1em;
}

.multiline-label-2 {
  margin-top: -2em;
}

.multiline-label-3 {
  margin-top: -3em;
}

form p,
form li,
form h1,
form h2,
form h3,
form h4,
form h5,
form h6 {
  color: white;
}
form .card-logo {
  box-sizing: border-box;
  border: 5px solid rgba(222, 182, 103, 0.6);
  height: 3em;
}
form .btn-contain button {
  background-color: rgba(222, 182, 103, 0.6);
  border: rgba(222, 182, 103, 0.6);
  height: 30px;
  margin-bottom: 10px;
}
form .btn-contain button a {
  color: white;
}

.datepicker-container {
  background-color: #444343;
  border-bottom: 5px solid #deb767;
}
.datepicker-container .date-text {
  color: #deb767;
}
.datepicker-container .month-next svg,
.datepicker-container .month-prev svg {
  fill: white;
}
.datepicker-container .month-next:focus,
.datepicker-container .month-prev:focus {
  background-color: rgba(222, 182, 103, 0.3);
}
.datepicker-container .datepicker-date-display,
.datepicker-container .datepicker-calendar-container {
  background-color: #444343;
  border-color: #deb767;
}
.datepicker-container .datepicker-date-display .datepicker-day-button,
.datepicker-container .datepicker-calendar-container .datepicker-day-button {
  border-radius: 0;
}
.datepicker-container .datepicker-date-display .datepicker-day-button:focus,
.datepicker-container .datepicker-calendar-container .datepicker-day-button:focus {
  background-color: rgba(222, 182, 103, 0.3);
}
.datepicker-container .datepicker-date-display td:not(.is-disabled) .datepicker-day-button,
.datepicker-container .datepicker-calendar-container td:not(.is-disabled) .datepicker-day-button {
  color: white;
}
.datepicker-container .datepicker-date-display td.is-disabled .datepicker-day-button,
.datepicker-container .datepicker-calendar-container td.is-disabled .datepicker-day-button {
  color: #6c757d;
}
.datepicker-container .datepicker-date-display td.is-selected .datepicker-day-button,
.datepicker-container .datepicker-calendar-container td.is-selected .datepicker-day-button {
  background-color: #deb767;
  color: #444343;
}
.datepicker-container .datepicker-date-display .btn-flat.datepicker-done,
.datepicker-container .datepicker-date-display .btn-flat.datepicker-cancel,
.datepicker-container .datepicker-calendar-container .btn-flat.datepicker-done,
.datepicker-container .datepicker-calendar-container .btn-flat.datepicker-cancel {
  color: #deb767;
}
.datepicker-container .datepicker-date-display .btn-flat.datepicker-clear,
.datepicker-container .datepicker-calendar-container .btn-flat.datepicker-clear {
  color: white;
}

button[type=submit] {
  font-weight: bold !important;
}

.dropdown-content li,
.select-dropdown li {
  background-color: #444343 !important;
}
.dropdown-content li.disabled span,
.select-dropdown li.disabled span {
  color: darkgrey !important;
}
.dropdown-content li span,
.select-dropdown li span {
  color: white !important;
}

.input-label {
  font-weight: bold;
  color: rgba(222, 182, 103, 0.6);
}

input[type=checkbox] + span::after {
  border-color: #deb767;
  border-radius: 0;
}

input[type=checkbox].filled-in:checked + span:not(.lever):after {
  background-color: #deb767;
  color: #deb767;
  border-color: transparent;
}

input[type=checkbox].filled-in:checked + span:not(.lever):before {
  border-color: transparent;
}

input[type=checkbox] + span:focus, input[type=checkbox] + span:active, input[type=checkbox] + span::selection,
input[type=checkbox]:focus,
input[type=checkbox]:active,
input[type=checkbox]::selection,
input[type=radio] + span:focus,
input[type=radio] + span:active,
input[type=radio] + span::selection,
input[type=radio]:focus,
input[type=radio]:active,
input[type=radio]::selection {
  background-color: rgba(222, 182, 103, 0.3);
}

.input-checkbox {
  font-weight: bold;
  color: rgba(222, 182, 103, 0.6);
}
.input-checkbox input {
  border-color: rgba(222, 182, 103, 0.6);
}
.input-checkbox [type=radio]:not(:checked) + span:before {
  border-radius: 0;
  border-color: #444343 !important;
  background-color: #444343 !important;
}
.input-checkbox [type=radio]:checked + span:before {
  border-radius: 0;
  border-color: #444343 !important;
  background-color: #444343 !important;
}
.input-checkbox [type=radio]:checked + span:after {
  border-color: #444343 !important;
  background-color: #deb767 !important;
}
.input-checkbox [type=checkbox] + span:after {
  border-radius: 0;
  border-color: #deb767 !important;
}

.input-field {
  /* label color */
  /* label focus color */
  /* label underline focus color */
  /* valid color */
  /* invalid color */
  /* icon prefix focus color */
  /* Chrome, Safari, Edge, Opera */
  /* Firefox */
}
.input-field input[type=text],
.input-field input[type=number],
.input-field input[type=email],
.input-field textarea {
  box-sizing: border-box;
  padding-left: 0.5em;
  margin-top: 0.5em;
  color: white;
  border-bottom: 1px solid transparent;
  background-color: #444343;
}
.input-field label {
  font-weight: bold;
  color: rgba(222, 182, 103, 0.6);
}
.input-field input[type=text]:focus + label,
.input-field input[type=number]:focus + label,
.input-field input[type=email]:focus + label,
.input-field textarea:focus + label {
  color: #deb767 !important;
}
.input-field textarea {
  min-height: 6em;
}
.input-field textarea + label,
.input-field input + label {
  transform: translateY(-14px) scale(0.8);
  transform-origin: 0 0;
}
.input-field input[type=text]:focus,
.input-field input[type=number]:focus,
.input-field input[type=email]:focus,
.input-field textarea:focus {
  border-bottom: 1px solid #deb767 !important;
  box-shadow: 0 1px 0 0 #deb767 !important;
}
.input-field input[type=text].valid,
.input-field input[type=number].valid,
.input-field input[type=email].valid,
.input-field textarea.valid {
  border-bottom: 1px solid #000;
  box-shadow: 0 1px 0 0 #000;
}
.input-field input[type=text].invalid,
.input-field input[type=number].invalid,
.input-field input[type=email].invalid,
.input-field textarea.invalid {
  border-bottom: 1px solid #000;
  box-shadow: 0 1px 0 0 #000;
}
.input-field .prefix.active {
  color: #deb767;
}
.input-field input::-webkit-outer-spin-button,
.input-field input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.input-field input[type=number] {
  -moz-appearance: textfield;
}

input[name=form_delivery_method]:not(:checked) ~ .input-field {
  display: none;
}

input[name=form_delivery_method]:not(:checked) ~ .input-field input {
  visibility: hidden;
}
