nav {
  width: 100%;
  height: 80px;
  position: absolute;
  z-index: 999;
  box-shadow: none;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0), #444343);
}
nav .brand-logo {
  filter: invert(100%);
  height: 64px;
  width: 148px;
  cursor: pointer;
  color: white;
}
nav .sidenav {
  background-color: #444343;
  z-index: 1000;
}
nav .sidenav li:not(.gold) a {
  color: white;
}
nav .nav-wrapper a i {
  font-size: 40px !important;
}
nav .nav-wrapper ul.dropdown-content {
  border-bottom: 3px solid #deb767;
}
nav .nav-wrapper ul.dropdown-content li a {
  color: white !important;
  text-align: left !important;
  padding: 1em 2em 1em 2em !important;
}
nav .nav-wrapper ul.dropdown-content li:hover {
  background-color: #383434 !important;
}
nav .nav-wrapper ul:not(.dropdown-content) {
  display: flex;
  padding-left: 10%;
  width: 70%;
}
nav .nav-wrapper ul:not(.dropdown-content) a.active {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0));
}
nav .nav-wrapper ul:not(.dropdown-content) > li {
  display: flex;
  width: 100%;
  justify-content: center;
}
nav .nav-wrapper ul:not(.dropdown-content) > li a {
  padding: 0;
  width: 100%;
  text-align: center;
}
nav .nav-wrapper ul:not(.dropdown-content) > li a:hover {
  background-color: transparent;
}
nav .nav-wrapper ul:not(.dropdown-content) > li:hover {
  cursor: pointer;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0));
}

.scrolled-nav-default {
  animation: scrolled-nav-default 500ms linear;
}

.scrolled-nav {
  position: fixed;
  background: none;
  background-color: rgba(68, 67, 67, 0.96);
  animation: nav-fade-in 200ms linear;
}

@keyframes nav-fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes scrolled-nav-default {
  0% {
    background-color: rgba(68, 67, 67, 0.96);
  }
  100% {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0), #444343);
  }
}
