::-webkit-scrollbar {
    width: .5em !important;
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
}

::-webkit-scrollbar-thumb {
    background-color: rgba(222, 182, 103) !important;
    outline: 1px solid slategrey !important;
    border-radius: 10px !important;
}
