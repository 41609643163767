.about-container {
  padding-top: 30vh;
}
.about-container .boss-card {
  margin-bottom: 4em;
  text-align: center;
}
.about-container .boss-card .boss-name {
  font-family: WorkSans-Regular, sans-serif;
  color: #deb767;
}
.about-container .boss-card .boss-title {
  font-family: WorkSans-Regular, sans-serif;
  color: #deb767;
}
.about-container .boss-card h2 {
  color: #deb767;
}
.about-container .boss-card p {
  color: white;
  text-align: justify;
}
.about-container .boss-card .boss-image {
  border-radius: 50%;
  overflow: hidden;
  height: 250px;
  width: 250px;
  margin: auto;
}
.about-container .boss-card .boss-image img {
  height: 250px;
  width: 250px;
}
.about-container .group-photo img {
  border: 5px solid #deb767;
  width: 100%;
}
.about-container .employee-container .employee-card {
  text-align: center;
}
.about-container .employee-container .employee-card .employee-name {
  word-spacing: 9999px;
  font-family: WorkSans-Regular, sans-serif;
  color: #deb767;
}
.about-container .employee-container .employee-card .employee-title {
  font-family: WorkSans-Regular, sans-serif;
  color: #deb767;
}
.about-container .employee-container .employee-card .image-container {
  border-radius: 50%;
  margin: auto;
  height: 200px;
  width: 200px;
  overflow: hidden;
}
.about-container .employee-container .employee-card .image-container img {
  height: auto;
  width: 100%;
}
.about-container .employee-container .employee-card .employee-summary {
  padding: 1em;
}
.about-container .employee-container .employee-card .employee-summary p {
  color: white;
}
