.fader {
  position: relative;
  width: 100%;
  height: 120px;
  bottom: -35px;
  z-index: 100;
  top: -60px;
  background: -webkit-gradient(linear, left bottom, left top, from(rgba(80, 77, 77, 0.9)), color-stop(#504D4D), to(rgba(0, 0, 0, 0)));
  background: linear-gradient(0deg, rgba(80, 77, 77, 0.9), #504D4D, rgba(0, 0, 0, 0));
}

.fader-reverse {
  position: relative;
  width: 100%;
  height: 120px;
  z-index: 100;
  top: 120px;
  background: -webkit-gradient(linear, left top, left bottom, from(#504D4D), color-stop(rgba(80, 77, 77, 0.9)), to(rgba(0, 0, 0, 0)));
  background: linear-gradient(180deg, #504D4D, rgba(80, 77, 77, 0.9), rgba(0, 0, 0, 0));
}
