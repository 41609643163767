footer {
  padding-top: 2em;
  font-family: WorkSans-Regular, sans-serif;
  font-size: 1rem;
  color: #deb767;
  border-top: 3px solid #deb767;
  text-align: center;
}
footer .footer-bottom-bar {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.4);
  line-height: 30px;
  background-color: #deb767;
  text-align: center;
  padding-left: 0.5em;
  padding-right: 0.5em;
  min-height: 30px;
}
footer .footer-bottom-bar a {
  color: rgba(0, 0, 0, 0.6);
}
footer .footer-link {
  color: #deb767;
}
footer .footer-link h6 {
  text-decoration: underline;
}
footer .btn-gold {
  margin-top: 1em;
  font-family: WorkSans-Regular, sans-serif;
  border: none;
  background-color: #deb767;
  padding: 0.5em;
  width: 50%;
}
footer .btn-gold a {
  color: white;
}
footer .social-icon-container {
  text-align: center;
}
footer .footer-icon {
  height: 2em;
  width: 2em;
  display: inline-block;
  cursor: pointer;
}
footer .fb {
  background: url("../../assets/img/social-media-icons/fb.png") no-repeat center;
}
footer .ig {
  background: url("../../assets/img/social-media-icons/ig.png") no-repeat center;
}
footer .yt {
  background: url("../../assets/img/social-media-icons/yt.png") no-repeat center;
}

@media screen and (max-width: 992px) {
  footer .row-col {
    margin-bottom: 2em;
  }
  footer button {
    width: 50vw;
  }
}
